import "../App.css";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import Input from "../components/Input";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Table from "../components/Table";
import { useParams, useHistory } from "react-router-dom";
import MyLaserApi from "../api/MyLaserApi";
import DataTable from "react-data-table-component";
import ImageListUpload from "../components/ImageListUpload";
import S3Handler from "../services/S3Handler";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const ContainerTreatment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.blue {
        color: #102935;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;

const OptionButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.3%;
    background-color: #edf0f5;
    color: #102935;
    padding: 10px;
    font-family: CenturyGothicRegular;
    border: none;
    border-right: 1px solid #cdd3d9;

    &.selected {
        font-family: CenturyGothicBold;
        background-color: #102935;
        color: #fff;
        border-radius: 10px;
    }
    &.last {
        border-right: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

const ImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    padding: 15px;
    border: none;
    background-color: #f8f9fb;
    border-radius: 10px;
`;

const AddDocumentButton = styled.label`
    background-color: #f0b594;
    border: 0;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.05);
    font-family: CenturyGothicBold;
    font-size: 14px;
    padding: 10px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
`;

function NotesAndProtocoles(props) {
    let params = useParams();
    let history = useHistory();
    let location = useLocation();

    let [nap, setNap] = useState([]);
    let [type, setType] = useState("notes");
    let [modalIsShown, showModal] = useState(false);

    let [name, setName] = useState("");
    let [content, setContent] = useState("");
    let [documents, setDocuments] = useState([]);
    let [disable, setDisable] = useState(false);

    let user = useSelector((state) => state.user);

    useEffect(() => {
        reload();
    }, [type]);

    useEffect(() => {
        if (location.search.includes("type=notes")) {
            setType("notes");
        } else if (location.search.includes("type=protocoles")) {
            setType("protocoles");
        }
    }, [location.search]);
    // useEffect(() => {
    //     setType(searchParams.get("type"));
    // }, [searchParams]);

    let reload = () => {
        MyLaserApi.getNotesAndProtocoles().then((res) => {
            if (res.status === 200) {
                setDataForm(res.data);
            }
        });
    };

    let setDataForm = (data) => {
        let lines = data.map((el) => {
            return {
                id: el.id,
                date: moment(el.created_at).format("DD/MM/YYYY"),
                type: el.type,
                name: el.name,
                attachments: el.attachments,
                data: el.data,
            };
        });

        setNap(lines);
    };

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header type="client" />

                    <ContainerTreatment>
                        <Row className="spaced-items center">
                            <Text className="bold blue">
                                Notes & Protocoles
                            </Text>
                        </Row>

                        <Row className="center" style={{ marginBottom: 15 }}>
                            <OptionButton
                                className={type === "notes" ? "selected" : null}
                                onClick={() => setType("notes")}
                            >
                                Notes
                            </OptionButton>
                            <OptionButton
                                className={
                                    type === "protocoles"
                                        ? "selected last"
                                        : "last"
                                }
                                onClick={() => setType("protocoles")}
                            >
                                Protocoles
                            </OptionButton>

                            {user.role === 1 && (
                                <div
                                    style={{
                                        textDecorationLine: "none",
                                        width: "33%",
                                        marginRight: "10px",
                                        display: "flex",
                                    }}
                                >
                                    <Button
                                        style={{ width: "100%" }}
                                        onClick={() => showModal(true)}
                                    >
                                        Ajouter{" "}
                                        {type === "notes"
                                            ? "une note"
                                            : "un protocole"}
                                    </Button>
                                </div>
                            )}
                        </Row>

                        {type === "notes" && (
                            <DataTable
                                title={`Notes`}
                                noDataComponent="Il n'y a aucune note à afficher"
                                pagination={30}
                                data={nap.filter((el) => el.type == "notes")}
                                columns={[
                                    {
                                        name: "Date",
                                        selector: (row) => row.date,
                                        sortable: true,
                                    },
                                    {
                                        name: "Notes",
                                        selector: (row) => row.name,
                                        sortable: true,
                                    },
                                    {
                                        name: "Détail",
                                        selector: (row) => (
                                            <span
                                                style={{
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                Voir le détail de la note
                                            </span>
                                        ),
                                        sortable: true,
                                    },
                                    ...(user.role === 1
                                        ? [
                                              {
                                                  name: "Supprimer",
                                                  selector: (row) => (
                                                      <span
                                                          style={{
                                                              textDecoration:
                                                                  "underline",
                                                          }}
                                                          onClick={() => {
                                                              MyLaserApi.call({
                                                                  method: "DELETE",
                                                                  url: `/notesandprotocoles/${row.id}`,
                                                              }).then((res) => {
                                                                  reload();
                                                              });
                                                          }}
                                                      >
                                                          Supprimer
                                                      </span>
                                                  ),
                                              },
                                          ]
                                        : []),
                                ]}
                                expandableRows
                                expandOnRowClicked={true}
                                expandableRowsComponent={({ data }) => {
                                    return (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                marginTop: "25px",
                                                marginBottom: "25px",
                                            }}
                                        >
                                            <h5>{data.name}</h5>
                                            <p>{data.data}</p>

                                            {data.attachments.map((el) => {
                                                return [
                                                    <a
                                                        href={el}
                                                        target="_blank"
                                                    >
                                                        {" "}
                                                        <pre>
                                                            {el.replace(
                                                                /^.*[\\\/]/,
                                                                ""
                                                            )}{" "}
                                                            (Ouvrir)
                                                        </pre>
                                                    </a>,
                                                    <iframe
                                                        src={el}
                                                        style={{
                                                            width: "100%",
                                                            height: "600px",
                                                        }}
                                                        frameBorder="0"
                                                        scrolling="scrolling"
                                                    ></iframe>,
                                                ];
                                            })}
                                        </div>
                                    );
                                }}
                            />
                        )}

                        {type === "protocoles" && (
                            <iframe
                                src="https://my-laser.fr/protocoles/"
                                style={{ width: "100%", height: "4000px" }}
                            ></iframe>
                        )}

                        <Modal
                            size="lg"
                            show={modalIsShown !== false}
                            onHide={() => {
                                showModal(false);
                            }}
                            title={`Ajouter ${
                                type === "notes" ? "une note" : "un protocole"
                            }`}
                        >
                            <Input
                                noIcon
                                placeholder={`Nom ${
                                    type === "notes"
                                        ? "de la note"
                                        : "du protocole"
                                }`}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />

                            {type === "protocole" && (
                                <Input
                                    noIcon
                                    className="multiline"
                                    placeholder="Détail de la note ici…"
                                    value={content}
                                    textArea
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            )}

                            <div
                                className="upload__image-wrapper"
                                style={{ width: "100%" }}
                            >
                                {documents.map((document, index) => (
                                    <div
                                        key={index}
                                        className="image-item"
                                        style={{}}
                                    >
                                        <ImagesContainer>
                                            <Row
                                                className="spaced-items"
                                                style={{
                                                    marginBottom: 0,
                                                    marginTop: 0,
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        marginBottom: 0,
                                                        width: "auto",
                                                        marginTop: 0,
                                                    }}
                                                >
                                                    <Image src={"/img.svg"} />
                                                    <Text
                                                        className="gray"
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        {document.file.name}

                                                        <br />

                                                        <Button
                                                            className="no-background"
                                                            style={{
                                                                color: "#F25151",
                                                            }}
                                                            onClick={
                                                                () => true
                                                                // onImageRemove(
                                                                //     index
                                                                // )
                                                            }
                                                        >
                                                            Supprimer
                                                        </Button>
                                                    </Text>
                                                </Row>
                                            </Row>
                                        </ImagesContainer>

                                        <div className="image-item__btn-wrapper"></div>
                                    </div>
                                ))}
                            </div>

                            <input
                                id="add-document"
                                style={{ width: 0, height: 0 }}
                                type="file"
                                name="imageUploader"
                                class="fileUploader uploadFile"
                                onChange={(event) => {
                                    let filetmp = event.target.files[0];

                                    var reader = new FileReader();
                                    reader.readAsDataURL(filetmp);
                                    reader.onload = function () {
                                        setDocuments([
                                            ...documents,
                                            {
                                                file: filetmp,
                                                result: reader.result,
                                            },
                                        ]);
                                    };
                                }}
                            />

                            <AddDocumentButton
                                htmlFor="add-document"
                                style={{ marginTop: "15px" }}
                            >
                                Ajouter des documents
                            </AddDocumentButton>

                            <Button
                                className="blue"
                                style={{
                                    width: "100%",
                                    marginBottom: 20,
                                    marginTop: "15px",
                                }}
                                disabled={disable}
                                onClick={async () => {
                                    // onImageUpload();

                                    let attachments =
                                        await S3Handler.uploadFromListDocuments(
                                            documents
                                        );

                                    setDisable(true);

                                    MyLaserApi.createNotesAndProtocoles({
                                        attachments: attachments,
                                        name: name,
                                        data: content,
                                        type: type,
                                    }).then(() => {
                                        reload();
                                        showModal(false);
                                        setDisable(false);
                                    });
                                }}
                            >
                                Créer{" "}
                                {type === "notes" ? "la note" : "le protocole"}
                            </Button>
                        </Modal>
                    </ContainerTreatment>
                </Col>
            </Row>
        </Container>
    );
}

export default NotesAndProtocoles;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Col, Row, Form } from "react-bootstrap";
import { Calendar } from "react-date-range";

import * as locales from "react-date-range/dist/locale";

import Button from "../../../components/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import HourPicker from "../../../components/HourPicker";
import EmployeePicker from "../../../components/EmployeePicker";

import SessionHeader from "../../../components/SessionHeader";

//api
import MyLaserApi from "../../../api/MyLaserApi";

import ItemDetails from "../../../components/ItemDetails";
import { useSelector } from "react-redux";

import ImageListUpload from "../../../components/ImageListUpload";
import ImageListUploaded from "../../../components/ImageListUploaded";
import S3Handler from "../../../services/S3Handler";
import Picker from "../../../components/Picker";
import Input from "../../../components/Input";

import moment from "moment";

import TimeInput from "react-time-picker-input";

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.label {
        font-family: CenturyGothicBold;
        color: #6b7389;
        font-size: 15px;
        margin-bottom: 20px;
    }
    &.selected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        text-decoration-line: underline;
    }
    &.unselected {
        font-family: CenturyGothicBold;
        font-size: 16px;
        margin-bottom: 20px;
        color: #9fa9ae;
    }
`;

function UpdateSession() {
    const history = useHistory();
    const params = useParams();
    const [label, setLabel] = useState("");

    useEffect(() => {
        switch (params.type) {
            case "laser":
                return setLabel("Épilation Laser");
            case "mesotherapy":
                return setLabel("Soin Mésothérapie");
            case "hydrafacial":
                return setLabel("Soin Hydrafaciale");
            case "ah":
                return setLabel("Injection Acide Hyaluronique (AH)");
            case "botox":
                return setLabel("Autre Injection");
            case "hyacorp":
                return setLabel("Injection Hyacorp");
            case "peeling":
                return setLabel("Peeling TCA visage");
            case "hair":
                return setLabel("Traitement Capillaire");
            case "cryolipolise":
                return setLabel("Cryolipolise");
            case "cryolipolyse":
                return setLabel("Cryolipolise");
            case "peeling-intimate":
                return setLabel("Peeling Intime");
            case "blemiderm":
                return setLabel("Blemiderm");
            case "dermamelan":
                return setLabel("Dermamelan");
            case "prx":
                return setLabel("Soin PRX");
            case "lips-bleaching":
                return setLabel("Lips Bleaching");
            default:
                return setLabel("");
        }
    }, []);

    return (
        <Container fluid>
            <SessionHeader text={label} goBack={() => history.goBack()} />

            {params.type === "laser" && (
                <LaserSession operation={params.type} label={label} />
            )}
            {params.type !== "laser" && (
                <OtherSession operation={params.type} label={label} />
            )}
        </Container>
    );
}

function LaserSession({ operation, label }) {
    const history = useHistory();
    const params = useParams();

    const [date, setDate] = useState(new Date());
    // const [hour, setHour] = useState(null);

    const [hour, setHour] = useState(moment().format("HH:mm"));

    const [datum, setDatum] = useState({});

    const [employee, setEmployee] = useState(null);

    const [images, setImages] = useState([]);
    const [cAttachments, setCAttachments] = useState([]);

    const [session, setSession] = useState({});
    const [levreParams, setLevreParams] = useState(false);

    const user = useSelector((state) => state.user);

    useEffect(() => {
        MyLaserApi.GetAgenciesClientsIdSession(
            params.id,
            params.id_session
        ).then(({ data }) => {
            if (!data.date.includes("1970")) {
                let elements = JSON.parse(data.data);

                if (data.area.includes("Maillot")) {
                    if (
                        elements.extra &&
                        Object.keys(elements.extra).length != 0
                    ) {
                        setLevreParams(true);
                    } else {
                        elements["extra"] = {};
                    }
                }

                setSession(data);

                setDatum(elements);
                setCAttachments(data.attachments);

                let cdate = moment(data.date);

                setDate(cdate.toDate());
                // setHour({ value: cdate.format('H:mm'), label: "Séance de " + cdate.format('HH:mm') })
                setHour(cdate.format("HH:mm"));

                setEmployee(data.employee_id);
            } else {
                setSession(data);

                let elements = JSON.parse(data.data);

                if (data.area.includes("Maillot")) {
                    if (
                        elements.extra &&
                        Object.keys(elements.extra).length != 0
                    ) {
                        setLevreParams(true);
                    } else {
                        elements["extra"] = {};
                    }
                }

                setDatum(elements);
                setCAttachments(data.attachments);
            }
        });
    }, []);

    return (
        <>
            <Row>
                <Col md={4} className="col">
                    <div>
                        <Text className="label">
                            Choisissez la date de la séance :
                            <br />
                            <br />
                        </Text>

                        <Calendar
                            onChange={setDate}
                            date={date}
                            maxDate={new Date()}
                            locale={locales["fr"]}
                        />

                        <br />

                        <TimeInput
                            value={hour}
                            eachInputDropdown
                            onChange={(value) => setHour(value)}
                        />

                        {/* <HourPicker onChange={(v) => setHour(v)}  value={hour} >  </HourPicker> */}

                        <EmployeePicker
                            label="Choisir le praticien qui a fait la séance"
                            value={employee}
                            onChange={(e) => {
                                setEmployee(e.target.value);
                            }}
                        ></EmployeePicker>
                    </div>
                </Col>

                <Col md={8} className="col">
                    <div style={{ width: "100%" }}>
                        <Col className="mt-4">
                            <Container fluid>
                                <ItemDetails
                                    show={true}
                                    text={"Zone " + session.area}
                                    selected={true}
                                    data={datum}
                                    onItemChange={(data) => {
                                        setDatum(data);
                                    }}
                                >
                                    <br />

                                    <div style={{ width: "100%" }}>
                                        <ImageListUploaded
                                            value={cAttachments}
                                            removeOne={(index) =>
                                                setCAttachments(
                                                    cAttachments.filter(
                                                        (v, i) => index !== i
                                                    )
                                                )
                                            }
                                        />

                                        <ImageListUpload
                                            value={images}
                                            onChange={(imageList) => {
                                                setImages(imageList);
                                            }}
                                            label="Ajouter une photo"
                                        />
                                    </div>
                                </ItemDetails>

                                {session.area &&
                                    datum.extra &&
                                    session.area.includes("Maillot") && (
                                        <>
                                            <Form.Check
                                                id="paramslevres"
                                                type="checkbox"
                                                onChange={() =>
                                                    setLevreParams(!levreParams)
                                                }
                                                checked={levreParams}
                                                label="Ajouter les paramètres pour les lèvres"
                                            />{" "}
                                            <br />
                                            {levreParams && (
                                                <ItemDetails
                                                    show={true}
                                                    text={
                                                        "Zone " +
                                                        session.area +
                                                        " (lèvres)"
                                                    }
                                                    selected={true}
                                                    data={datum["extra"]}
                                                    onItemChange={(data) => {
                                                        setDatum({
                                                            ...datum,
                                                            extra: data,
                                                        });
                                                    }}
                                                >
                                                    <br />

                                                    <div
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    ></div>
                                                </ItemDetails>
                                            )}
                                        </>
                                    )}

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        padding: 20,
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        disabled={(() => {
                                            if (user.role === 1) {
                                                return (
                                                    date == null ||
                                                    hour == null ||
                                                    employee == null
                                                );
                                            } else {
                                                return (
                                                    date == null || hour == null
                                                );
                                            }
                                        })()}
                                        style={{ width: "100%" }}
                                        onClick={async () => {
                                            let attachments =
                                                await S3Handler.uploadFromList(
                                                    images
                                                );

                                            let hh = hour.split(":");

                                            date.setHours(hh[0]);
                                            date.setMinutes(hh[1]);

                                            let obj = {
                                                date: date,
                                                data: datum,
                                                attachments: [
                                                    ...cAttachments,
                                                    ...attachments,
                                                ],
                                                type: operation,
                                                type_human: label,
                                            };

                                            if (user.role === 1) {
                                                obj["employee_id"] = employee;
                                            }

                                            MyLaserApi.UpdateAgenciesClientsIdSessions(
                                                params.id,
                                                obj,
                                                params.id_session
                                            ).then(() => {
                                                history.push(
                                                    `/patients/${params.id}/treatments/laser`
                                                );
                                            });
                                        }}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </Container>
                        </Col>
                    </div>
                </Col>
            </Row>
        </>
    );
}

function OtherSession({ operation, label }) {
    const params = useParams();
    const history = useHistory();

    const [date, setDate] = useState(new Date());
    const [hour, setHour] = useState(moment().format("HH:mm"));
    const [note, setNote] = useState("");

    const [images, setImages] = useState([]);

    const [area, setArea] = useState("");

    const [employee, setEmployee] = useState(null);

    const user = useSelector((state) => state.user);

    const [datum, setDatum] = useState({});

    const [cAttachments, setCAttachments] = useState([]);

    const [etiquetteTracage, setEtiquetteTracage] = useState([]);

    const [injectedMl, setInjectedMl] = useState(null);
    const [injectedMlAH, setInjectedMlAH] = useState(0.1);

    const [injectedMlAHTotal, setInjectedMlAHTotal] = useState(0);
    const [injectedMlAH2, setInjectedMlAH2] = useState([]);

    useEffect(() => {
        MyLaserApi.GetAgenciesClientsIdSession(
            params.id,
            params.id_session
        ).then(({ data }) => {
            console.log("----");

            //
            let elements = JSON.parse(data.data);
            setDatum(elements);
            setCAttachments(data.attachments);

            setArea(data.area);

            if (elements.etiquette) {
                setEtiquetteTracage([elements.etiquette]);
            }

            if (operation === "blemiderm" || operation === "dermamelan") {
                setDatum({ ...datum, skincare: elements.skincare || "Aucun" });
            }

            if (elements.injection && operation === "ah") {
                setInjectedMlAH(elements.injection.replace(" ml", ""));
            } else if (elements.injections && operation === "ah") {
                setInjectedMlAHTotal(elements.total_ml);
                setInjectedMlAH2(elements.injections);
            }

            if (elements.injection && operation !== "ah") {
                setInjectedMl({
                    label: elements.injection,
                    value: parseFloat(elements.injection.match(/\d/g).join("")),
                });
            }

            if (!data.date.includes("1970")) {
                let cdate = moment(data.date);

                setDate(cdate.toDate());
                setHour(cdate.format("HH:mm"));
                setEmployee(data.employee_id);

                setNote(elements.note);
            }
        });
    }, []);

    return (
        <Row>
            <Col xs={4} className="col">
                <div>
                    <Text className="label">
                        <br />
                        Choisissez la date de la séance :
                        <br />
                        <br />
                    </Text>

                    <Calendar
                        onChange={setDate}
                        date={date}
                        maxDate={new Date()}
                        locale={locales["fr"]}
                    />

                    <br />

                    <TimeInput
                        value={hour}
                        eachInputDropdown
                        onChange={(value) => setHour(value)}
                    />

                    <EmployeePicker
                        label="Choisir le praticien qui a fait la séance"
                        value={employee}
                        onChange={(e) => {
                            setEmployee(e.target.value);
                        }}
                    ></EmployeePicker>
                </div>
            </Col>

            <Col xs={4} className="col">
                <div style={{ width: "100%" }}>
                    <Row>
                        <Text className="label">Information sur la séance</Text>
                    </Row>

                    <Col className="mt-4">
                        <div style={{ width: "100%" }}>
                            {operation === "hydrafacial" && (
                                <Input
                                    readOnly={true}
                                    noIcon
                                    placeholder="Type de traitement"
                                    label="Type de traitement"
                                    value={area}
                                    styleInput={{ background: "white" }}
                                />
                            )}

                            {operation === "ah" && injectedMlAHTotal === 0 && (
                                <>
                                    <div style={{ width: "100%" }}>
                                        <Input
                                            readOnly={true}
                                            noIcon
                                            placeholder="Zone de traitement"
                                            label="Zone de traitement"
                                            value={area}
                                            styleInput={{ background: "white" }}
                                        />
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <Input
                                            readOnly={true}
                                            noIcon
                                            placeholder="Nombre de ml injecté"
                                            label="Nombre de ml injecté"
                                            value={injectedMlAH}
                                            styleInput={{ background: "white" }}
                                        />
                                    </div>
                                </>
                            )}

                            {operation === "ah" && injectedMlAHTotal != 0 && (
                                <>
                                    {injectedMlAH2.map((el, index) => (
                                        <>
                                            <div style={{ width: "100%" }}>
                                                <Input
                                                    readOnly={true}
                                                    noIcon
                                                    placeholder="Zone de traitement"
                                                    label="Zone de traitement"
                                                    value={el.area}
                                                    styleInput={{
                                                        background: "white",
                                                    }}
                                                />
                                            </div>

                                            <div style={{ width: "100%" }}>
                                                <Input
                                                    noIcon
                                                    placeholder="Nombre de ml injecté"
                                                    label="Nombre de ml injecté"
                                                    value={el.ml}
                                                    onChange={(e) => {
                                                        let newInjections =
                                                            injectedMlAH2.map(
                                                                (el, i) => {
                                                                    if (
                                                                        i ===
                                                                        index
                                                                    ) {
                                                                        return {
                                                                            ...el,
                                                                            ml: e
                                                                                .target
                                                                                .value,
                                                                        };
                                                                    } else {
                                                                        return el;
                                                                    }
                                                                }
                                                            );

                                                        setInjectedMlAH2(
                                                            newInjections
                                                        );
                                                    }}
                                                    styleInput={{
                                                        background: "white",
                                                    }}
                                                />
                                            </div>

                                            <hr />
                                        </>
                                    ))}
                                </>
                            )}

                            {(() => {
                                let injectedCount = injectedMlAH2.reduce(
                                    (p, c) => {
                                        return p + parseFloat(c.ml);
                                    },
                                    0
                                );

                                let min =
                                    injectedMlAHTotal -
                                    injectedMlAH2.length * 0.9;
                                let max = injectedMlAHTotal;

                                // min
                                if (
                                    injectedMlAHTotal != 0 &&
                                    (injectedCount < min || injectedCount > max)
                                ) {
                                    return (
                                        <div style={{ width: "100%" }}>
                                            <ContainerAlert>
                                                Le montant total des injections
                                                doit correspondre au montant
                                                payer ({injectedMlAHTotal}ml)
                                            </ContainerAlert>
                                        </div>
                                    );
                                }
                            })()}

                            {operation === "botox" && (
                                <>
                                    <Picker
                                        options={[
                                            "Rides frontales",
                                            "Rides de la patte d'oie",
                                            "Rides du lion",
                                            "Rides de plissement du nez",
                                        ].map((e) => ({ label: e, value: e }))}
                                        label="Zone de traitement"
                                        placeholder="Zone de traitement"
                                        onChange={(data) => setArea(data.value)}
                                        value={{ label: area, value: area }}
                                        isSearchable={false}
                                        isDisabled={true}
                                    />
                                </>
                            )}

                            {operation === "electrolyse" && (
                                <>
                                    <div style={{ width: "100%" }}>
                                        <Input
                                            // readOnly={true}
                                            noIcon
                                            placeholder="Zone de traitement"
                                            label="Zone de traitement"
                                            value={area}
                                            onChange={(e) =>
                                                setArea(e.target.value)
                                            }
                                            styleInput={{ background: "white" }}
                                        />
                                    </div>
                                    <div
                                        style={{ width: "100%", marginTop: 10 }}
                                    >
                                        <Picker
                                            backgroundColor={"white"}
                                            options={[
                                                {
                                                    label: "15 mins",
                                                    value: 15,
                                                },
                                                {
                                                    label: "30 mins",
                                                    value: 30,
                                                },
                                                {
                                                    label: "45 mins",
                                                    value: 45,
                                                },
                                                {
                                                    label: "60 mins",
                                                    value: 60,
                                                },
                                            ]}
                                            label="Durée du traitement"
                                            placeholder="Durée du traitement"
                                            onChange={(data) =>
                                                setDatum({
                                                    ...datum,
                                                    duration: data.value,
                                                })
                                            }
                                            value={{
                                                label: `${datum.duration} mins`,
                                                value: datum.duration,
                                            }}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div
                                        style={{ width: "100%", marginTop: 10 }}
                                    >
                                        <Picker
                                            backgroundColor={"white"}
                                            options={[
                                                "F2",
                                                "F3",
                                                "F4",
                                                "F2I",
                                                "F3I",
                                                "F4I",
                                                "F2G",
                                                "F3G",
                                                "F4G",
                                            ].map((e) => ({
                                                label: e,
                                                value: e,
                                            }))}
                                            label="Filament"
                                            placeholder="Filament"
                                            onChange={(data) =>
                                                setDatum({
                                                    ...datum,
                                                    filament: data.value,
                                                })
                                            }
                                            value={{
                                                label: datum.filament,
                                                value: datum.filament,
                                            }}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div
                                        style={{ width: "100%", marginTop: 10 }}
                                    >
                                        <Picker
                                            backgroundColor={"white"}
                                            options={[
                                                "Très fin",
                                                "Fin",
                                                "Moyen",
                                                "Gros",
                                                "Très gros",
                                            ].map((e) => ({
                                                label: e,
                                                value: e,
                                            }))}
                                            label="Réglage poil"
                                            placeholder="Réglage poil"
                                            onChange={(data) =>
                                                setDatum({
                                                    ...datum,
                                                    poil: data.value,
                                                })
                                            }
                                            value={{
                                                label: datum.poil,
                                                value: datum.poil,
                                            }}
                                            isSearchable={false}
                                        />
                                    </div>
                                </>
                            )}

                            {operation === "hyacorp" && (
                                <>
                                    <div style={{ width: "100%" }}>
                                        <Picker
                                            options={[
                                                {
                                                    value: 1,
                                                    label: "100 ml (50 ml par fesse)",
                                                },
                                                {
                                                    value: 2,
                                                    label: "200 ml (100 ml par fesse)",
                                                },
                                                {
                                                    value: 3,
                                                    label: "300 ml (150 ml par fesse)",
                                                },
                                                {
                                                    value: 4,
                                                    label: "400 ml (200 ml par fesse)",
                                                },
                                            ]}
                                            value={injectedMl}
                                            label="Nombre de ml injecté"
                                            placeholder="Nombre de ml injecté"
                                            onChange={(data) =>
                                                setInjectedMl(data)
                                            }
                                        />
                                    </div>
                                </>
                            )}

                            {(operation === "cryolipolise" ||
                                operation === "cryolipolyse") && (
                                <>
                                    <Picker
                                        options={[
                                            "Double menton",
                                            "Bras",
                                            "Poignées d'amour",
                                            "Ventre",
                                            "Hanche",
                                            "Cuisse",
                                            "Genou",
                                            "Ligne du soutien-gorge",
                                            "Dessous de bras",
                                            "Hanche supérieure",
                                            "Pli fessier",
                                        ].map((e) => ({ label: e, value: e }))}
                                        label="Zone de traitement"
                                        placeholder="Zone de traitement"
                                        onChange={(data) => setArea(data.value)}
                                        value={{ label: area, value: area }}
                                        isSearchable={false}
                                    />

                                    <div
                                        style={{
                                            width: "50%",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Input
                                            noIcon
                                            placeholder="Distance entre les orteils"
                                            label="Distance entre les orteils"
                                            value={datum.distance}
                                            styleInput={{ background: "white" }}
                                            onChange={(e) =>
                                                setDatum({
                                                    ...datum,
                                                    distance: e.target.value,
                                                })
                                            }
                                        />
                                        <Input
                                            noIcon
                                            placeholder="Hauteur"
                                            label="Hauteur"
                                            value={datum.height}
                                            styleInput={{ background: "white" }}
                                            onChange={(e) =>
                                                setDatum({
                                                    ...datum,
                                                    height: e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Input
                                            noIcon
                                            placeholder="Largeur"
                                            label="Largeur"
                                            value={datum.width}
                                            styleInput={{ background: "white" }}
                                            onChange={(e) =>
                                                setDatum({
                                                    ...datum,
                                                    width: e.target.value,
                                                })
                                            }
                                        />

                                        <Input
                                            noIcon
                                            placeholder="Applicateur"
                                            label="Applicateur"
                                            value={datum.applicateur}
                                            styleInput={{ background: "white" }}
                                            onChange={(e) =>
                                                setDatum({
                                                    ...datum,
                                                    applicateur: e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Input
                                            noIcon
                                            placeholder="Refroidissement"
                                            label="Refroidissement"
                                            value={datum.refroidissement}
                                            styleInput={{ background: "white" }}
                                            onChange={(e) =>
                                                setDatum({
                                                    ...datum,
                                                    refroidissement:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Input
                                            noIcon
                                            placeholder="Aspiration"
                                            label="Aspiration"
                                            value={datum.aspiration}
                                            styleInput={{ background: "white" }}
                                            onChange={(e) =>
                                                setDatum({
                                                    ...datum,
                                                    aspiration: e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div
                                        style={{
                                            width: "100%",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Input
                                            noIcon
                                            placeholder="Durée"
                                            label="Durée"
                                            value={datum.duree}
                                            styleInput={{ background: "white" }}
                                            onChange={(e) =>
                                                setDatum({
                                                    ...datum,
                                                    duree: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </>
                            )}

                            <Input
                                style={{ marginTop: "15px" }}
                                textArea
                                noIcon
                                className="multiline"
                                placeholder="Petite rougeur au niveau du nez"
                                label="Note"
                                value={note}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />

                            {operation === "blemiderm" && (
                                <>
                                    <Picker
                                        label="Skincare fournie"
                                        placeholder="Skincare fournie"
                                        options={[
                                            "Aucun",
                                            "Dermamelan home pack",
                                        ].map((e) => ({ label: e, value: e }))}
                                        value={{
                                            label: datum.skincare,
                                            value: datum.skincare,
                                        }}
                                        onChange={(data) =>
                                            setDatum({
                                                ...datum,
                                                skincare: data.value,
                                            })
                                        }
                                        backgroundColor={"white"}
                                    />
                                </>
                            )}

                            {operation === "dermamelan" && (
                                <>
                                    <Picker
                                        label="Skincare fournie"
                                        placeholder="Skincare fournie"
                                        options={[
                                            "Aucun",
                                            "Dermamelan home pack",
                                            "Dermamelan home pack 2 (J+30)",
                                            "Dermamelan home pack 3 (J+60)",
                                        ].map((e) => ({ label: e, value: e }))}
                                        value={{
                                            label: datum.skincare,
                                            value: datum.skincare,
                                        }}
                                        onChange={(data) =>
                                            setDatum({
                                                ...datum,
                                                skincare: data.value,
                                            })
                                        }
                                        backgroundColor={"white"}
                                    />
                                </>
                            )}
                        </div>
                    </Col>
                </div>
            </Col>

            <Col xs={4} className="col">
                <Col className="mt-4" style={{ width: "80%" }}>
                    {["peeling", "ah", "botox"].includes(operation) && (
                        <>
                            <Text className="label">
                                Etiquette de traçage :
                            </Text>

                            <div style={{ width: "100%" }}>
                                {etiquetteTracage &&
                                    etiquetteTracage[0] &&
                                    typeof etiquetteTracage[0] == "string" &&
                                    etiquetteTracage[0].includes("http") && (
                                        <ImageListUploaded
                                            value={etiquetteTracage}
                                            removeOne={(index) =>
                                                setEtiquetteTracage([])
                                            }
                                        />
                                    )}

                                <ImageListUpload
                                    value={etiquetteTracage}
                                    onChange={(imageList) => {
                                        setEtiquetteTracage(imageList);
                                    }}
                                    label="Ajouter l'étiquette de traçage"
                                    showButton={etiquetteTracage.length === 0}
                                />
                            </div>
                        </>
                    )}

                    <hr />

                    <Text className="label">Pièces Jointes :</Text>

                    <br />

                    <div style={{ width: "100%" }}>
                        <ImageListUploaded
                            value={cAttachments}
                            removeOne={(index) =>
                                setCAttachments(
                                    cAttachments.filter((v, i) => index !== i)
                                )
                            }
                        />

                        <ImageListUpload
                            value={images}
                            onChange={(imageList) => {
                                setImages(imageList);
                            }}
                            label="Ajouter une photo"
                        />
                    </div>

                    <br />
                    <br />

                    <hr />
                    <br />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        <Button
                            disabled={(() => {
                                if (user.role === 1) {
                                    return (
                                        date == null ||
                                        hour == null ||
                                        employee == null
                                    );
                                } else {
                                    return date == null || hour == null;
                                }
                            })()}
                            style={{ width: "100%" }}
                            onClick={async () => {
                                let attachments =
                                    await S3Handler.uploadFromList(images);

                                let hh = hour.split(":");

                                date.setHours(hh[0]);
                                date.setMinutes(hh[1]);

                                let obj = {
                                    date: date,
                                    type: operation,
                                    type_human: label,
                                    area: area || "-",
                                    data: {
                                        note: note,
                                    },
                                    attachments: [
                                        ...cAttachments,
                                        ...attachments,
                                    ],
                                };

                                if (user.role === 1) {
                                    obj["employee_id"] = employee;
                                }

                                if (
                                    operation === "cryolipolise" ||
                                    operation === "cryolipolyse" ||
                                    operation === "blemiderm" ||
                                    operation === "dermamelan" ||
                                    operation === "electrolyse"
                                ) {
                                    obj.data = { ...obj.data, ...datum };
                                }

                                if (
                                    etiquetteTracage.length !== 0 &&
                                    !etiquetteTracage[0].includes("http")
                                ) {
                                    let etiquette =
                                        await S3Handler.uploadFromList(
                                            etiquetteTracage
                                        );
                                    obj.data = {
                                        ...obj.data,
                                        etiquette: etiquette[0],
                                    };
                                }

                                if (operation === "ah") {
                                    let injectedCount = injectedMlAH2.reduce(
                                        (p, c) => {
                                            return p + parseFloat(c.ml);
                                        },
                                        0
                                    );

                                    let min =
                                        injectedMlAHTotal -
                                        injectedMlAH2.length * 0.9;
                                    let max = injectedMlAHTotal;

                                    // min
                                    if (
                                        injectedMlAHTotal != 0 &&
                                        (injectedCount < min ||
                                            injectedCount > max)
                                    ) {
                                        return;
                                    }

                                    obj.data = {
                                        ...obj.data,
                                        injections: injectedMlAH2,
                                        total_ml: injectedMlAHTotal,
                                    };
                                }

                                if (operation === "electrolyse") {
                                    if (datum.duration == 15) {
                                        obj.forced_price = 50;
                                    } else if (datum.duration == 30) {
                                        obj.forced_price = 95;
                                    } else if (datum.duration == 45) {
                                        obj.forced_price = 140;
                                    } else if (datum.duration == 60) {
                                        obj.forced_price = 180;
                                    }
                                }

                                MyLaserApi.UpdateAgenciesClientsIdSessions(
                                    params.id,
                                    obj,
                                    params.id_session
                                ).then(() => {
                                    history.goBack();
                                });
                            }}
                        >
                            Sauvegarder
                        </Button>
                    </div>
                </Col>
            </Col>
        </Row>
    );
}

const ContainerAlert = styled.div`
    color: #721c24;
    /* width: 90%; */
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
`;

export default UpdateSession;
